<template>
  <div class="page-content">
    <form class="md:w-1/3 mx-8 md:mx-auto mt-16 text-dcblue" v-on:submit.prevent="submitContactForm">
      <h1 class="self-center text-25625 font-bold mb-2">Sign up for a free trial</h1>
      <div class="mb-6">
        <h1 class="uppercase font-semibold mb-3 tracking-widest">Requested details</h1>
        <label class="mb-4 block">
          <span class="font-semibold">Full name</span>
          <input v-model="contactForm.full_name" name="name" class="form-input mt-1 p-3 block w-full border-packageborder"
                 placeholder="Your name" required>
        </label>

        <label class="mb-4 block">
          <span class="font-semibold">Company e-mail address</span>
          <input v-model="contactForm.e_mail" name="email" class="form-input mt-1 p-3 block w-full border-packageborder"
                 placeholder="email@email.com" required>
        </label>

        <label class="mb-4 block">
          <span class="font-semibold">Phone</span>
          <div class="flex">
            <input v-model="contactForm.phone" name="phone" class="form-input mt-1 p-3 block w-full border-packageborder"
                   placeholder="+45 35 65 35 65">
          </div>
        </label>

        <label class="mb-3 block pb-3">
          <span class="font-semibold">Company name</span>
          <input v-model="contactForm.company_name" name="company name" class="form-input mt-1 p-3 block w-full border-packageborder"
                 placeholder="" required>
        </label>

        <label class="mb-3 block border-dcborder border-solid border-b pb-3">
          <span class="font-semibold">Comments</span>
          <textarea v-model="contactForm.comments" name="comments" rows="6"
                    class="form-input mt-1 p-3 block w-full border-packageborder"></textarea>
        </label>

        <label class="mb-4 inline-flex items-center">
          <input type="checkbox" class="form-checkbox h-5 w-5 border-packageborder rounded-full" name="tosagreement"
                 value="tos" required>
          <span class="ml-2 text-lg">I agree with the <router-link class="link" :to="{ name: 'terms' }">terms of use</router-link> and the <router-link class="link" :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link></span>
        </label>

        <button type="submit"
           class="block send-request text-center w-full font-bold text-white uppercase bg-dcgreen text-xl py-1 mb-32">
          Send request
        </button>
      </div>
    </form>

    <div v-if="showModal"
         class="signup-confirm-modal fixed w-screen h-screen flex justify-center inset-0">
      <div class="content text-dcblue">
        <img class="m-auto mt-16 w-10 mb-6" src="@/assets/checkgreen.svg"/>
        <h1 class="text-175 font-semibold tracking-wide">Thank you!</h1>
        <p class="text-xl w-2/3 m-auto mb-6">
          Your request will be processed within the next 24h and you will be contacted by email upon decision.
        </p>
        <a v-on:click="toggle"
           class="bg-dcblue text-white text-lg uppercase font-bold px-10 py-2 tracking-wider">Continue</a>
      </div>
    </div>

  </div>
</template>

<script>

    export default {
        name: "SignUp",
        data: function () {
            return {
                showModal: false,
                contactForm: {
                    webform_id: "free_trial",
                    full_name: "",
                    e_mail: "",
                    phone: "",
                    company_name: "",
                    comments: "",
                }
            }
        },
        methods: {
            submitContactForm() {
                let _self = this;

                _self.axios.post('/webform_rest/submit', _self.contactForm).then(() => {
                    _self.toggle();

                    _self.contactForm.full_name =
                        _self.contactForm.e_mail =
                            _self.contactForm.phone =
                                _self.contactForm.company_name =
                                    _self.contactForm.comments = ""
                })
            },
            toggle: function () {
                this.showModal = !this.showModal;
            }
        }
    }
</script>

<style lang="scss" scoped>
  .signup-confirm-modal {
    background: rgba(200, 200, 200, 0.8);

    .content {
      height: 400px;
      width: 500px;
      position: absolute;
      left: 50%;
      margin-left: -200px;
      top: 50%;
      margin-top: -200px;
      background: white;
      text-align: center;
    }
  }

  .send-request:hover {
      cursor: pointer;
  }

  .link {
      text-decoration: underline;
  }
</style>
