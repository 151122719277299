<template>
  <div v-if="renderLibraries"  class="library bg-dcgray pb-32 page-content">
    <div class="library-header mb-12 h-64 bg-no-repeat bg-cover bg-center flex justify-center">
      <h1 class="self-center text-white text-25625 font-bold">Library</h1>
    </div>

    <div class="mx-6 md:mx-20 lg:mx-36 xl:mx-48 mb-10">
      <h2 class="ml-4 text-175 font-semibold">Full list of contents</h2>
      <p class="ml-4 text-xl">We are constantly adding new content. Any regulatory documents or standards not listed <br>can be made available upon request.
      </p>
    </div>

    <div class="library-list">

      <div class="document-access sm:mx-6 md:mx-20 lg:mx-36 xl:mx-48">
        <div class="font-semibold sm:border-solid sm:border sm:border-packageborder rounded-lg sm:px-4 px-0 pt-5 pb-0 text-center md:mr-5 mr-1 sm:w-20 w-16">Free</div>
        <div class="font-semibold sm:border-solid sm:border sm:border-packageborder rounded-lg sm:px-4 px-0 pt-5 pb-0 text-center md:mr-5 mr-1 sm:w-20 w-16">Bronze</div>
        <div class="font-semibold sm:border-solid sm:border sm:border-packageborder rounded-lg sm:px-4 px-0 pt-5 pb-0 text-center md:mr-5 mr-1 sm:w-20 w-16">Silver</div>
        <div class="font-semibold sm:border-solid sm:border sm:border-packageborder rounded-lg sm:px-4 px-0 pt-5 pb-0 text-center md:mr-5 mr-1 sm:w-20 w-16">Gold</div>
        <div class="font-semibold sm:border-solid sm:border sm:border-packageborder rounded-lg sm:px-4 px-0 pt-5 pb-0 text-center md:mr-5 mr-1 sm:w-20 w-16">Ship</div>
      </div>

      <div v-for="(vocabulary, vname) in renderLibraries" v-bind:key="vname" :id="vname" class="libraryTable pt-10 ml-6 md:mx-20 lg:mx-36 xl:mx-48 w-100 xs:w-auto">

        <h3 class="uppercase font-semibold w-5/12 sm:w-full"> {{ vname }} </h3>

        <div v-for="(term, subvocabulary) in vocabulary" v-bind:key="term.tid" class="libraryTable-row">

          <div
            v-if="!Array.isArray(term)"
            :id="term.name"
            :class="{ 'bg-white' : (subvocabulary % 2) === 0  }"
            class="">
            <span class="">{{ term.name }}</span>
            <span>{{ (term.long_name == "" ? '-' : term.long_name) }}</span>
            <span class="">
                <img v-if="term.free === 'True'" class="" src="@/assets/checkgreen.svg"/>
            </span>
            <span class="">
                <img v-if="term.bronze === 'True'" class="" src="@/assets/checkgreen.svg"/>
                <p v-else class="extDownloadMobile">LINK TO LOCATION</p>
            </span>
            <span class="">
                <img v-if="term.silver === 'True'" class="" src="@/assets/checkgreen.svg"/>
            </span>
            <span class="">
                <img v-if="term.gold === 'True'" class="" src="@/assets/checkgreen.svg"/>
            </span>
            <span class="">
                <img v-if="term.ship === 'True'" class="" src="@/assets/checkgreen.svg"/>
            </span>
          </div>

          <div v-else :id="subvocabulary" class="subvocabulary">
            <h3 class="font-semibold" >{{ subvocabulary }}</h3>
            <div
              v-for="(value, index) in term" v-bind:key="value.tid"
              :id="value.name"
              :class="{ 'bg-white' : (index % 2) === 0  }"
              class="">
              <span class="">{{ (value.long_name == "" ? '-' : value.name) }}</span>
              <span>{{ (value.long_name == "" ? value.name : value.long_name) }}</span>
              <span class="">
                <img v-if="value.free === 'True'" class="" src="@/assets/checkgreen.svg"/>
              </span>
              <span class="">
                <img v-if="value.bronze === 'True'" class="" src="@/assets/checkgreen.svg"/>
                <p v-else class="extDownloadMobile">LINK TO LOCATION</p>
              </span>
              <span class="">
                <img v-if="value.silver === 'True'" class="" src="@/assets/checkgreen.svg"/>
              </span>
              <span class="">
                <img v-if="value.gold === 'True'" class="" src="@/assets/checkgreen.svg"/>
              </span>
              <span class="">
                <img v-if="value.ship === 'True'" class="" src="@/assets/checkgreen.svg"/>
              </span>
            </div>

          </div>

        </div>

  </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "Library",
  data: function () {
    return {
      renderLibraries: null
    }
  },
  computed: {
    ...mapGetters([
      'getLibrary'
    ])
  },
  created() {
    let _self = this;

    this.$store.dispatch('loadLibraries').then(() => {
      _self.renderLibraries = {}
      _self.getLibrary.forEach((termElement) => {
        // Add 1st layer
        if (!_self.renderLibraries.hasOwnProperty(termElement.vocabulary)) {

          _self.renderLibraries[termElement.vocabulary] = [];
        }

        if (termElement.parent === "" && termElement.has_children === "True") {

          // Maybe add 2nd layer
          if (!_self.renderLibraries[termElement.vocabulary].hasOwnProperty(termElement.name)) {

            if (Array.isArray(_self.renderLibraries[termElement.vocabulary])) {
              _self.renderLibraries[termElement.vocabulary] = {}
            }

            _self.renderLibraries[termElement.vocabulary][termElement.name] = []
          }
        } else if (termElement.parent === "" && termElement.has_children === "False") {
          _self.renderLibraries[termElement.vocabulary][termElement.name] = []
          _self.renderLibraries[termElement.vocabulary][termElement.name].push({
            name: termElement.name,
            long_name: termElement.long_name,
            direct_download: termElement.field_direct_download,
            external_download: termElement.field_external_download,
            free: termElement.field_free,
            bronze: termElement.field_bronze,
            silver: termElement.field_silver,
            gold: termElement.field_gold,
            ship: termElement.field_ship
          })
          _self.renderLibraries[termElement.vocabulary][termElement.name].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        } else {

          if (_self.renderLibraries[termElement.vocabulary].hasOwnProperty(termElement.parent)) {
            _self.renderLibraries[termElement.vocabulary][termElement.parent].push({
              name: termElement.name,
              long_name: termElement.long_name,
              direct_download: termElement.field_direct_download,
              external_download: termElement.field_external_download,
              free: termElement.field_free,
              bronze: termElement.field_bronze,
              silver: termElement.field_silver,
              gold: termElement.field_gold,
              ship: termElement.field_ship
            });
            _self.renderLibraries[termElement.vocabulary][termElement.parent].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          } else {
            _self.renderLibraries[termElement.vocabulary].push({
              name: termElement.name,
              long_name: termElement.long_name,
              direct_download: termElement.field_direct_download,
              external_download: termElement.field_external_download,
              free: termElement.field_free,
              bronze: termElement.field_bronze,
              silver: termElement.field_silver,
              gold: termElement.field_gold,
              ship: termElement.field_ship
            })
            _self.renderLibraries[termElement.vocabulary].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          }

        }

      })
    });
  },
  updated() {
    if(this.$route.params.library) {
      let anchor = document.getElementById(this.$route.params.library);
      if(anchor) {
        anchor.scrollIntoView();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.library-header {
  background-image: url('../assets/heroback.jpg');
}

.library-list {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: scroll;
  overflow-y: auto;
}

.libraryTable {
  margin-bottom: 3rem;

  h3 {
    margin: 15px 0;
  }

  .extDownloadMobile {
    display: none;
    font-size: 12px;
    color: #3D90D1;
  }

  &-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    & > div {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;

      span {
        padding: 10px;
        box-sizing: border-box;
        align-self: center;
      }

      span:nth-of-type(1) {
        width: 10%;

        @media (max-width: 639px) {
          width: 100%;
          font-weight: bold;
        }
      }
      span:nth-of-type(2) {
        width: calc(90% - 480px);

        @media (max-width: 767px) {
          width: calc(90% - 440px);
        }
        @media (max-width: 639px) {
          width: calc(100% - 340px);
        }
      }
      span:nth-of-type(3),
      span:nth-of-type(4),
      span:nth-of-type(5),
      span:nth-of-type(6) {
        margin-right: 1.25rem;
        width: 5rem;

        img {
          margin: auto;
        }

        @media (max-width: 767px) {
          margin-right: 0.25rem;
        }

        @media (max-width: 639px) {
          width: 4rem;
          text-align: center;
        }
      }

      span:nth-of-type(7) {
        width: 5rem;

        img {
          margin: auto;
        }

        @media (max-width: 639px) {
          width: 4rem;
        }
      }
      //   span:nth-of-type(4) {
      //       width: calc(109.5px - 1.25em);
      //       img {
      //           margin: auto;
      //       }

      //       @media (max-width: 600px) {
      //           display: none;
      //       }
      //   }

    }

    .subvocabulary {
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  /* @media (max-width: 600px) {
      .extDownloadMobile {
          display: block;
      }
  } */
}

.document-access {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;

  //@media screen and(max-width:420px) {
  //  right: initial;
  //  left: 150px;
  //}

  div {
    min-width: calc(20% - 1rem);
  }

  div:last-of-type {
    margin-right: 0;
  }

  //   div:nth-of-type(2) {
  //       display: none;
  //       max-width: 85px;
  //       border: none;
  //   }

  //   @media (max-width: 600px) {
  //       max-width: 85px;
  //       right: 25px;
  //       top: -65px;

  //       div:nth-of-type(1), div:nth-of-type(3) {
  //           display: none;
  //       }

  //       div:nth-of-type(2) {
  //           display: block;
  //       }
  //   }
}

.placeholder {
  visibility: hidden;
}


</style>
