<template>
  <div class="page-content">
    <div class="header-img">
      <h1 class="self-center text-white text-4xl font-bold">Request Access</h1>
    </div>

    <div v-if="showModal"
         class="signup-confirm-modal fixed w-screen h-screen flex justify-center inset-0">
      <div class="content text-dcblue">
        <img class="m-auto mt-16 w-10 mb-6" src="@/assets/checkgreen.svg"/>
        <h1 class="text-175 font-semibold tracking-wide">Thank you!</h1>
        <p class="text-xl w-2/3 m-auto mb-6">
          Your request will be processed within the next 24h and you will be contacted by email upon decision.
        </p>
        <a @click="toggle" class="bg-dcblue text-white text-lg uppercase font-bold px-10 py-2 tracking-wider">Continue</a>
      </div>
    </div>

    <form class="md:w-1/2 lg:w-1/3 mx-8 md:mx-auto text-dcblue">
      <div class="mb-10">
        <h1 class="uppercase text-lg font-semibold mb-2 tracking-widest">Account type</h1>

        <div class="mb-4">
          <label class="inline-flex items-center w-full md:w-1/2">
            <input v-model="requestAccessForm.account_type" type="radio" name="accountType" value="Single user" class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
            <span class="ml-2 text-lg">Single user</span>
          </label>

          <label class="inline-flex items-center w-full md:w-1/2">
            <input v-model="requestAccessForm.account_type" type="radio" name="accountType" value="Corporate account" class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
            <span class="ml-2 text-lg">Corporate account</span>
          </label>
        </div>

        <h1 class="font-semibold mb-2">Package</h1>
        <div class="mb-4">
          <label class="inline-flex items-center w-1/3">
            <input v-model="requestAccessForm.package" id="bronze" value="bronze" type="radio" name="package" class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
            <span class="ml-2 text-lg">Bronze</span>
          </label>
          <label class="inline-flex items-center w-1/3">
            <input v-model="requestAccessForm.package" id="silver" value="silver" type="radio" name="package" class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
            <span class="ml-2 text-lg">Silver</span>
          </label>
          <label class="inline-flex items-center w-1/3">
            <input v-model="requestAccessForm.package" id="gold" value="gold"  type="radio" name="package" class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
            <span class="ml-2 text-lg">Gold</span>
          </label>
        </div>
      </div>

      <div class="mb-10">
        <h1 class="uppercase text-lg font-semibold mb-2 tracking-widest">Additional contents</h1>

        <h1 class="font-semibold mb-2">Flag states</h1>

        <div class="mb-2">
          <label
            class="inline-flex w-1/2 md:w-1/3 mb-2"
            v-for="flagState in getFlagStates"
            v-bind:key="flagState.tid.value">
            <input
              v-model="requestAccessForm.flag_states"
              type="checkbox"
              class="form-checkbox h-5 w-5 border-packageborder rounded-sm"
              :id="flagState.name"
              :value="flagState.name">
            <span class="ml-2 text-lg">{{stripHtml(flagState.desc)}}</span>
          </label>
        </div>

        <h1 class="font-semibold mb-2">Other content</h1>
        <label
          class="inline-flex w-1/2 md:w-1/3 mb-2"
          v-for="otherContents in getOtherContents"
          v-bind:key="otherContents.tid">
          <input
            v-model="requestAccessForm.other_content"
            type="checkbox"
            :id="otherContents.name"
            :value="otherContents.name"
            class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
          <span class="ml-2 text-lg">{{stripHtml(otherContents.name)}}</span>
        </label>

        <h1 class="font-semibold mb-2">Standards</h1>
        <label
          class="inline-flex w-1/2 md:w-1/3 mb-2"
          v-for="standard in getStandards"
          v-bind:key="standard.tid[0].value">
          <input
            v-model="requestAccessForm.standards"
            type="checkbox"
            :id="standard.name[0].value"
            :value="standard.name[0].value"
            class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
          <span class="ml-2 text-lg">{{stripHtml(standard.name[0].value)}}</span>
        </label>

        <h1 class="font-semibold mb-2">UN</h1>
        <label
          class="inline-flex w-1/2 md:w-1/3 mb-2"
          v-for="un in getUN"
          v-bind:key="un.tid[0].value">
          <input
            v-model="requestAccessForm.un"
            type="checkbox"
            :id="un.name[0].value"
            :value="un.name[0].value"
            class="form-checkbox h-5 w-5 border-packageborder rounded-sm">
          <span class="ml-2 text-lg">{{stripHtml(un.name[0].value)}}</span>
        </label>
      </div>

      <div class="mb-12">
        <h1 class="uppercase text-lg font-semibold mb-2 tracking-widest">Support</h1>
        <div class="mb-2">
          <label class="inline-flex items-center w-full mb-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 border-packageborder rounded-sm" v-model="requestAccessForm.support">
            <span class="ml-2 text-lg">1 hour/month</span>
          </label>
        </div>
      </div>

      <div class="mb-6">
        <h1 class="uppercase text-lg font-semibold mb-3 tracking-widest">Personal info</h1>
        <label class="mb-4 block">
          <span class="font-semibold">Full name</span>
          <input class="form-input mt-1 p-3 block w-full border-packageborder" placeholder="John Doe" v-model="requestAccessForm.full_name">
        </label>

        <label class="mb-4 block">
          <span class="font-semibold">E-mail</span>
          <input class="form-input mt-1 p-3 block w-full border-packageborder" placeholder="john@doe.com" v-model="requestAccessForm.email">
        </label>

        <label class="mb-4 block">
          <span class="font-semibold">Phone</span>
          <div class="flex">
            <select class="form-select mt-1 p-3 block w-1/5 mr-3" v-model="requestAccessForm.country_code">
              <option
                v-for="countryCode in getCountryCodes"
                v-bind:key="countryCode.tid">
                <img v-if="countryCode.uri.length" class="object-contain inline-block" :src="countryCode.uri" />
                {{countryCode.code}}
              </option>
            </select>
            <input class="form-input mt-1 p-3 block w-4/5 border-packageborder" placeholder="35 65 35 65" v-model="requestAccessForm.phone_number">
          </div>
        </label>

        <label class="mb-3 block border-dcborder border-solid border-b pb-3">
          <span class="font-semibold">Company name</span>
          <input class="form-input mt-1 p-3 block w-full border-packageborder" placeholder="John Doe Shipping" v-model="requestAccessForm.company_name">
        </label>

        <label class="mb-4 inline-flex items-center">
          <input type="checkbox" class="form-checkbox h-5 w-5 border-packageborder rounded-full" name="tosagreement" v-model="tos" value="tos">
         <span class="ml-2 text-lg">I agree with the <router-link class="link" :to="{ name: 'terms' }">terms of use</router-link> and the <router-link class="link" :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link></span>
        </label>
        <span v-if="tos_error" class="w-full error text-sm inline-flex items-center">Terms of use is required</span>
      </div>

      <a
        class="block submit text-center w-full font-bold text-white uppercase bg-dcgreen text-xl py-1 mb-32"
        @click="submitContactForm">
          Send request
      </a>
    </form>
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "RequestAccess",
        data: function () {
            return {
                requestAccessForm : {
                    webform_id: "request_access",
                    account_type: '',
                    package: '',
                    flag_states: [],
                    other_content: [],
                    standards: [],
                    un: [],
                    support: false,
                    full_name: '',
                    email: '',
                    country_code: '',
                    phone_number: '',
                    company_name: '',

                },
                tos: false,
                showModal: false,
                tos_error: false
            }
        },
        computed: {
            ...mapGetters([
                'getFlagStates',
                'getOtherContents',
                'getCountryCodes',
                'getStandards',
                'getUN'
            ]),
        },
        created () {
            this.$store.dispatch('loadFlagStates');
            this.$store.dispatch('loadOtherContents');
            this.$store.dispatch('loadCountryCodes');
            this.$store.dispatch('loadStandards');
            this.$store.dispatch('loadUN');
        },
        methods: {
            stripHtml: (string) => {
                if (string) {
                  return string.replace(/(<([^>]+)>)/ig,"");
                }
            },
            submitContactForm: async function () {
                let _self = this;
                if (_self.tos) {
                    let formData = _self.requestAccessForm;

                    formData.flag_states = _self.requestAccessForm.flag_states.join(', ');
                    formData.other_content = _self.requestAccessForm.other_content.join(', ');
                    formData.standards = _self.requestAccessForm.standards.join(', ');
                    formData.un = _self.requestAccessForm.un.join(', ');

                    let res = await _self.axios.post('/webform_rest/submit', formData);
                    _self.tos_error =  false

                    if (res.status === 200) {
                        _self.toggle();




                        _self.requestAccessForm = {
                            webform_id: "request_access",
                            account_type: '',
                            package: '',
                            flag_states: [],
                            other_content: [],
                            standards: [],
                            un: [],
                            support: false,
                            full_name: '',
                            email: '',
                            country_code: '',
                            phone_number: '',
                            company_name: '',
                        }
                        _self.tos = false

                    }
                } else {
                    _self.tos_error =  true
                }


            },
            toggle() {
                this.showModal = !this.showModal;
            }
        }
    }
</script>

<style scoped lang="scss">
    .submit {
        &:hover {
            cursor: pointer
        }
    }

    .error {
        color: #ff3860;
    }
  .signup-confirm-modal {
    background: rgba(200, 200, 200, 0.8);

    .content {
      height: 400px;
      width: 500px;
      position: absolute;
      left: 50%;
      margin-left: -200px;
      top: 50%;
      margin-top: -200px;
      background: white;
      text-align: center;
    }
  }

  .link {
      text-decoration: underline;
  }
</style>
