<template>
  <div class="text-dcblue bg-dcgray pb-24 page-content">
    <div class="header-img">
      <h1 class="self-center text-white text-26875 font-bold">Support</h1>
    </div>

    <form class="flex md:w-2/3 mx-8 md:mx-auto" v-on:submit.prevent="submitContactForm">
        <div class="w-2/3">
            <h1 class="text-175 font-semibold mt-12">E-mail us</h1>
            <p class="w-full text-xl mb-12">
              If you have questions regarding regulatory compliance or our services, please fill in below request form.
              You are also welcome to contact us directly by mail or phone
            </p>

            <div class="mb-6">
              <h1 class="uppercase text-lg font-semibold mb-3 tracking-widest">Personal info</h1>
              <label class="mb-4 block">
                <span class="font-semibold">Full name</span>
                <input v-model="contactForm.name" required class="form-input mt-1 p-3 block w-full border-packageborder">
              </label>

              <label class="mb-3 block pb-3">
                <span class="font-semibold">Company name</span>
                <input v-model="contactForm.company" required class="form-input mt-1 p-3 block w-full border-packageborder">
              </label>

              <label class="mb-4 block">
                <span class="font-semibold">E-mail</span>
                <input v-model="contactForm.email" required class="form-input mt-1 p-3 block w-full border-packageborder">
              </label>

              <label class="mb-3 block pb-3">
                <span class="font-semibold">Subject</span>
                <input v-model="contactForm.subject" required class="form-input mt-1 p-3 block w-full border-packageborder">
              </label>

              <label class="mb-3 block pb-3">
                <span class="font-semibold">Message</span>
                <textarea v-model="contactForm.message" required rows="3" class="form-input mt-1 p-3 block w-full border-packageborder"></textarea>
              </label>

              <label class="mb-4 inline-flex items-center">
                <input type="checkbox" required class="form-checkbox h-5 w-5 border-packageborder rounded-full" name="tosagreement" v-model="tos" value="tos">
               <span class="ml-2 text-lg">I agree with the <router-link class="link" :to="{ name: 'terms' }">terms of use</router-link> and the <router-link class="link" :to="{ name: 'PrivacyPolicy' }">privacy policy</router-link></span>
              </label>
              <span v-if="tos_error" class="w-full error text-sm inline-flex items-center">Terms of use is required</span>
            </div>

            <div class="flex justify-end">
                <span v-if="tos_error" class="w-full error text-sm inline-flex items-center">Terms of use is required</span>
              <button type="submit" class="submit block text-center w-1/4 font-bold text-white uppercase bg-dcblue text-xl py-1 mb-16">
                Send
              </button>
            </div>
        </div>


      <div class="ml-auto justify-end">

        <div class="mt-12">
          <p class="uppercase text-md font-semibold tracking-widest mb-4">our office</p>
          <p class="text-md">
            Symfonivej 18 <br>
            2730 Herlev <br>
            Denmark <br>
          </p>
          <br>
        </div>
        <img class="w-full" src="@/assets/map.png"/>
      </div>

    </form>

     <div v-if="showModal"
         class="signup-confirm-modal fixed w-screen h-screen flex justify-center inset-0">
      <div class="content text-dcblue">
        <img class="m-auto mt-16 w-10 mb-6" src="@/assets/checkgreen.svg"/>
        <h1 class="text-175 font-semibold tracking-wide">Thank you!</h1>
        <p class="text-xl w-2/3 m-auto mb-6">
          Thank you for contacting us, we'll respond to your message as soon as possible.
        </p>
        <a v-on:click="toggle"
           class="bg-dcblue text-white text-lg uppercase font-bold px-10 py-2 tracking-wider">Continue</a>
      </div>
    </div>

  </div>
</template>

<script>

    export default {
        name: "SignUp",
        data: function () {
            return {
                showModal: false,
                contactForm: {
                    webform_id: "contact",
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                    comments: "",
                }
            }
        },
        methods: {
            submitContactForm() {
                let _self = this;

                _self.axios.post('/webform_rest/submit', _self.contactForm).then(() => {
                    _self.toggle();

                    _self.contactForm.name =
                        _self.contactForm.email =
                            _self.contactForm.subject =
                                _self.contactForm.message =
                                    _self.contactForm.company = ""
                })
            },
            toggle: function () {
                this.showModal = !this.showModal;
            }
        }
    }
</script>

<style lang="scss">
.submit {
    &:hover {
        cursor: pointer
    }
}
    .link {
        text-decoration: underline;
    }

    .signup-confirm-modal {
    background: rgba(200, 200, 200, 0.8);

    .content {
      height: 400px;
      width: 500px;
      position: absolute;
      left: 50%;
      margin-left: -200px;
      top: 50%;
      margin-top: -200px;
      background: white;
      text-align: center;
    }
  }

</style>
